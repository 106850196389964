.p-home {
  padding:0;
  padding-top:10px;

  &__mozaic {
    a, span {
      margin-bottom:10px;
      display: block;
      img {
        width:100%;
      }
    }

    &--partners {
      a {
        img {
          width:auto;
          max-width: 60%;
        }
      }
    }
  }

  &__mozaic-link {
    @include size(277px);
    @include f(33px,$c-white);
    span {
      @include size(100%);
      @include flexAlign();
      text-align: center;
      flex-wrap: wrap;
    }
    background: $c-theme;

    &:hover {
      color:$c-white;
    }

    &--alt {
      background: $c-white;
      padding:14% 5%;
      @include f(46px,$c-theme,300);

      &:hover {
        color:$c-theme;
      }
    }

    @include mq($screen-lg) {
      @include size(227px);
      &--alt {
        @include f(33px);
      }
    }
    @include mq($screen-md) {

      max-height:355px;
      max-width: 355px;
      @include size(auto);
      @include f(35px);
    }
    @include mq($screen-sm) {
      @include f(26px)
    }
  }

  &__mozaic-partner {
    @include size(277px);
    background: $c-white;
    @include flexAlign();
    display: flex!important;

    &:hover {
      background: $c-theme;
    }

    @include mq($screen-lg) {
      @include size(227px);
    }
    @include mq($screen-md) {
      @include size(173px);
    }
    @include mq($screen-sm) {
      @include size(100%);
      padding:30px;
    }
  }

  &__search {
    background: $c-white;
    padding: 50px 0;
    text-align: center;

    header {
      margin-bottom:20px;
      h2 {
        @include f(40px);
        margin-bottom:10px;
      }
      h3 {
        @include f(18px,0,0,0,uppercase);
      }
    }

    .form-standard {
      width:40%;
      margin:auto;
      margin-bottom:20px;
    }
    > a {
      @include f(16px,$c-black,600,0,$ff-roboto);
    }

    @include mq($screen-sm) {
      .form-standard {
        width:80%;
      }
    }
  }
}
